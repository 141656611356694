<template>
  <div>
    <b-row class="justify-content-end mb-2">
      <BButton variant="primary" @click="createUser">
        <feather-icon icon="PlusIcon" /> Novo usuário
      </BButton>
    </b-row>
    
    <datatable ref="usersDatatable" @changeSelectedRows="changeSelectedRows">
      <template #table-caption>
        <div class="d-flex justify-content-start align-items-center">
          <b-form-checkbox @change="$refs.usersDatatable.toggleSelectedAllRows($event)">
            Selecionar todos
          </b-form-checkbox>

          <b-button variant="outline-primary" class="ml-1" @click="blockUsers" :disabled="selectedRows < 1">
            <feather-icon icon="LockIcon" /> Bloquear 
          </b-button>
          <b-button variant="primary" class="ml-1" @click="unblockUsers" :disabled="selectedRows < 1">
            <feather-icon icon="UnlockIcon" /> Desbloquear 
          </b-button>
        </div>
      </template>
    </datatable>    
  </div>
</template>


<script>
import { EventBus } from "@/services/event_bus";
import { BCard, BCol, BRow, BButton, BModal, BTable, BBadge, BFormCheckbox, BTooltip } from 'bootstrap-vue';
import Datatable from "@/views/common/crud/components/Datatable.vue";
export default {
  name: 'AdminUsersIndex',
  components: {
    BButton,
    BCard,
    BCol,
    BModal,
    BRow,
    BTable,
    BBadge,
    BFormCheckbox,
    BTooltip,
    Datatable
  },
  data () {
    return {
      selectedRows: [],
    }
  },
  mounted() {
    // EventBus.$on('show-modal-on-action', this.edit);
  },
  methods: {
    changeSelectedRows(selectedRows) {
      this.selectedRows = selectedRows;
    },
    createUser () {
      this.$router.push({ name: 'admin-users.create' });
    },
    blockUsers () {
      const ids = this.selectedRows.map(user => user.id);
      this.$store.dispatch("admin/blockUsers", ids).then((response) => {
        EventBus.$emit("success", "Usuários bloqueados com sucesso!");
        this.refreshTable();
      });
    },
    unblockUsers () {
      const ids = this.selectedRows.map(user => user.id);
      this.$store.dispatch("admin/unblockUsers", ids).then((response) => {
        EventBus.$emit("success", "Usuários desbloqueados com sucesso!");
        this.refreshTable();
      });
    },
    edit (item) {
      this.$router.push({ name: 'admin-users.edit', params: { id: item.id } });
    },
    refreshTable () {
      this.$refs.usersDatatable.getData();
    }
  }
}
</script>